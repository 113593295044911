<mat-nav-list appAccordion>
    <span><img src="assets/images/header/logo.png" style="height: 40px;padding-left: 10px;"></span> 
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem(login,role)" routerLinkActive="selected" group="{{menuitem.state}}">
        <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <mat-icon>{{ menuitem.icon }}</mat-icon> 
            <span>{{ menuitem.name }}</span> 
            <span fxFlex></span> 
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> 
        </a>

        
    </mat-list-item>
</mat-nav-list>