<button mat-icon-button class="m-r-15" *ngIf="role == 'admin'"> <img src="assets/images/header/scanner.png" class="profile-pic" [routerLink]="['/scanner']"> </button>
<button mat-icon-button class="m-r-15" *ngIf="role == 'admin'"> <img src="assets/images//header/video.png" class="profile-pic"[routerLink]="['/home']"> </button>
<!--<button mat-icon-button class="m-r-15"> <img src="assets/images//header/video.png" class="profile-pic"[routerLink]="['/home']"></button>-->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-15" *ngIf="role == 'admin'"> <img src="assets/images/header/user.png" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="redirectToChangePassword()">
        <mat-icon>account_box</mat-icon> Change Password </button>
    <button mat-menu-item (click)="logOut()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>

