<div *ngIf="!!ovSettings" id="videoRoomNavBar" [class.boundsLight]="lightTheme">
  <app-room-config
    *ngIf="showConfigRoomCard"
    [ovSettings]="ovSettings"
    [externalConfig]="externalConfig"
    (join)="onConfigRoomJoin()"
    (leaveSession)="leaveSession()"
    (publisherCreated)="emitPublisher($event)"
  ></app-room-config>

  <div *ngIf="localUsers && localUsers.length > 0">
    <div *ngIf="isConnectionLost" class="reconnecting-container"></div>
    <app-toolbar
      [lightTheme]="lightTheme"
      [compact]="compact"
      [mySessionId]="mySessionId"
      [ovSettings]="ovSettings"
      [hasVideoDevices]="hasVideoDevices"
      [hasAudioDevices]="hasAudioDevices"
      [isWebcamVideoEnabled]="oVSessionService.hasWebcamVideoActive()"
      [isWebcamAudioEnabled]="toolbarMicIconEnabled()"
      [isScreenEnabled]="oVSessionService.isScreenShareEnabled()"
      [isAutoLayout]="isAutoLayout"
      [isConnectionLost]="isConnectionLost"
      (camButtonClicked)="toggleCam()"
      (micButtonClicked)="toggleMic()"
      (screenShareClicked)="toggleScreenShare()"
      (layoutButtonClicked)="toggleSpeakerLayout()"
      (leaveSessionButtonClicked)="leaveSession()"
    ></app-toolbar>

      <div class="sidenav-main">
        <div id="layout" class="bounds" [class.boundsLight]="lightTheme">
          <div
            class="OT_root OT_publisher custom-class"
            id="localUser"
            *ngFor="let localUser of localUsers"
            [style.display]="!localUser.streamManager?.stream?.videoActive ? 'none' : 'block'"
          >
          <!-- Only webcam video will be shown if webcamera is available -->
            <stream-component
              [user]="localUser"
              [videoSizeBig]="localUser.videoSizeBig"
              (nicknameClicked)="onNicknameUpdate($event)"
              (replaceScreenTrackClicked)="onReplaceScreenTrack($event)"
              (toggleVideoSizeClicked)="onToggleVideoSize($event)"
            ></stream-component>
          </div>

          <div
            *ngFor="let user of remoteUsers"
            class="OT_root OT_publisher custom-class"
            id="remoteUsers"
            [style.display]="!user.streamManager?.stream?.videoActive ? 'none' : 'block'"
          >
            <stream-component
              [user]="user"
              [videoSizeBig]="user.videoSizeBig"
              (toggleVideoSizeClicked)="onToggleVideoSize($event)"
            ></stream-component>
          </div>
        </div>

    <app-footer
      [lightTheme]="lightTheme"
      [participants]="remoteUsers"
    ></app-footer>
  </div>
</div>
