<div class="main-container">
    <mat-toolbar color="primary" class="topbar telative" style="background-color:#26c6da">
        <button  mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex></span>
        <app-header *ngIf="routerlinkCheck()"></app-header>
    </mat-toolbar>

    <mat-sidenav-container  class="full-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <mat-sidenav  #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches"  >
                <app-sidebar [login]="routerlinkCheck()" [role]="role"></app-sidebar>  
        </mat-sidenav>
        <mat-sidenav-content class="page-wrapper">
                 
                <div class="page-content">
                   
                        <router-outlet><app-spinner></app-spinner></router-outlet>
                    
                </div>    
               
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>