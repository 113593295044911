<mat-toolbar
	id="header"
	role="heading"
	[class.headerLight]="lightTheme"
	class="container"
	fxLayout
	fxLayoutAlign="center"
	fxLayoutGap="10px"
>
	<div fxFlex="20%">
		<!--<app-toolbar-logo [lightTheme]="lightTheme" [sessionId]="mySessionId" [compact]="compact" [logoUrl]="logoUrl"></app-toolbar-logo>-->
	</div>
	<div fxFlex="70%" fxFlexOrder="2">
		<div align="center" class="buttonsContainer">
			<button
				id="navMicrophoneButton"
				mat-icon-button
				(click)="toggleMicrophone()"
				[disabled]="isConnectionLost"
				*ngIf="hasAudioDevices && (ovSettings | hasAudio)"
			>
				<mat-icon *ngIf="isWebcamAudioEnabled" matTooltip="Mute your audio">mic</mat-icon>
				<mat-icon *ngIf="!isWebcamAudioEnabled" color="warn" matTooltip="Unmute your audio">mic_off</mat-icon>
			</button>

			<!-- Camera button -->
			<button
				id="navCameraButton"
				mat-icon-button
				(click)="toggleCamera()"
				[disabled]="isConnectionLost"
				*ngIf="!this.ovSettings || (hasVideoDevices && (this.ovSettings | hasVideo))"
			>
				<mat-icon *ngIf="isWebcamVideoEnabled" matTooltip="Mute your cam">videocam</mat-icon>
				<mat-icon *ngIf="!isWebcamVideoEnabled" color="warn" matTooltip="Unmute your cam">videocam_off</mat-icon>
			</button>

			<!-- Screenshare button -->
			<button
				id="navScreenButton"
				mat-icon-button
				(click)="toggleScreenShare()"
				[disabled]="isConnectionLost"
				*ngIf="ovSettings | hasScreenSharing"
			>
				<mat-icon *ngIf="!isScreenEnabled" matTooltip="Screen share" color="warn">stop_screen_share</mat-icon>
				<mat-icon *ngIf="isScreenEnabled" matTooltip="Stop sharing">screen_share</mat-icon>
			</button>

			<!-- Fullscreen button -->
			<button mat-icon-button (click)="toggleFullscreen()" [disabled]="isConnectionLost" *ngIf="ovSettings | hasFullscreen">
				<mat-icon matTooltip="Fullscreen">{{ fullscreenIcon }}</mat-icon>
			</button>

			<!-- Automatic / grid layout button -->
			<button
				mat-icon-button
				(click)="toggleSpeakerLayout()"
				[disabled]="isConnectionLost"
				*ngIf="ovSettings | hasLayoutSpeaking"
			>
				<mat-icon matTooltip="Enable speaker layout" color="warn" *ngIf="!isAutoLayout">voice_over_off</mat-icon>
				<mat-icon matTooltip="Disable speaker layout" *ngIf="isAutoLayout">record_voice_over</mat-icon>
			</button>

			<!-- Leave seassion button -->
			<button mat-icon-button (click)="leaveSession()" id="navLeaveButton" *ngIf="ovSettings | hasExit">
				<mat-icon color="warn" matTooltip="Leave the session">power_settings_new</mat-icon>
			</button>
		</div>
	</div>
	<div fxFlex="10%" fxFlexOrder="3" align="end">
		<!--<button mat-icon-button (click)="toggleChat()" [disabled]="isConnectionLost" *ngIf="ovSettings | hasChat">
			<mat-icon
				matBadge="{{ newMessagesNum }}"
				[matBadgeHidden]="newMessagesNum === 0"
				matBadgePosition="above before"
				matTooltip="Chat"
				matBadgeColor="accent"
				>chat</mat-icon
			>
		</button>-->
	</div>
</mat-toolbar>
