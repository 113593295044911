<div *ngIf="this._user"
	class="OT_widget-container"
	[id]="'container-' + this._user.streamManager.stream.streamId"
	#streamComponent
	(dblclick)="toggleVideoSize()"
>
	<div class="nickname" [class.fullscreen]="isFullscreen">
		<div (click)="toggleNicknameForm()" class="nicknameContainer" selected *ngIf="!toggleNickname">
			<!--<span id="nickname">{{ this._user.nickname }}</span>
			<span *ngIf="!this._user.streamManager.remote"> (edit)</span>-->
		</div>
		<div *ngIf="toggleNickname && !this._user.streamManager.remote" [class.fullscreen]="isFullscreen" id="dialogNickname">
			<button mat-icon-button (click)="toggleNicknameForm()" id="closeButton">
				<mat-icon matTooltip="Close">highlight_off</mat-icon>
			</button>
			<form id="nicknameForm">
				<mat-form-field color="primary">
					<input
						matInput
						#nicknameInput
						placeholder="Nick: {{ this._user.nickname }}"
						[formControl]="nicknameFormControl"
						[errorStateMatcher]="matcher"
						(keypress)="eventKeyPress($event)"
						autocomplete="off"
					/>
					<mat-error *ngIf="nicknameFormControl.hasError('required')"> Nickname is <strong>required</strong> </mat-error>
					<mat-error *ngIf="nicknameFormControl.hasError('maxlength')"> Nickname is <strong>too long!</strong> </mat-error>
				</mat-form-field>
			</form>
		</div>
	</div>

	<ov-video
		[streamManager]="this._user.streamManager"
		[mutedSound]="mutedSound"
		(toggleVideoSizeEvent)="toggleVideoSize($event)"
	></ov-video>
	<div class="statusIcons">
		<div id="statusMic" *ngIf="!this._user.streamManager.stream.audioActive">
			<mat-icon>mic_off</mat-icon>
		</div>
		<div id="statusCam" *ngIf="!this._user.streamManager.stream.videoActive">
			<mat-icon>videocam_off</mat-icon>
		</div>
	</div>

	<div class="videoButtons">
		<button mat-icon-button id="videoZoomButton"  (click)="toggleVideoSize()">
			<mat-icon>{{ this.videoSizeIcon }}</mat-icon>
		</button>
		<button mat-icon-button id="fullscreenButton" (click)="toggleFullscreen()">
			<mat-icon>{{ this.fullscreenIcon }}</mat-icon>
		</button>
		<button mat-icon-button id="volumeButton" *ngIf="this._user.streamManager.remote" (click)="toggleSound()">
			<mat-icon *ngIf="!mutedSound" matTooltip="Mute sound">volume_up</mat-icon>
			<mat-icon *ngIf="mutedSound" color="warn" matTooltip="Unmute sound">volume_off</mat-icon>
		</button>
		<button
			mat-icon-button
			(click)="replaceScreenTrack()"
			id="changeScreenButton"
			*ngIf="!this._user.streamManager.remote && this._user.isScreen()"
		>
			<mat-icon matTooltip="Choose screen">picture_in_picture</mat-icon>
		</button>
	</div>
</div>
